export const userTypes = [
  {
    _id: "RANCHER",
    key: "G",
    title: "Ganadero",
    disabled: false,
    description:
      "Este tipo de usuario está enfocado en la gestión y control del ganado. Podrá registrar la producción, supervisar el estado de salud de los animales, y acceder a herramientas diseñadas específicamente para optimizar el manejo pecuario. ",
  },
  {
    _id: "PROFESSIONAL",
    key: "P",
    title: "Profesional",
    disabled: true,
    description:
      "Ideal para usuarios que brindan asesorías y consultorías. Tendrán acceso a análisis técnicos, reportes avanzados, y módulos especializados para apoyar a ganaderos en la toma de decisiones estratégicas.",
  },
];
