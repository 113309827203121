// TreatmentCreatePage.jsx
import React from "react";
import CustomDialog from "../../../components/CustomDialog"; // Ajusta la ruta a tu componente de diálogo
import TreatmentForm from "../../HealthControl/Treatment/Forms/TreatmentForm";

/**
 * @component
 * @description Componente de diálogo que contiene el formulario para agregar un tratamiento a un animal
 */
const TreatmentCreatePage = ({ parentPathname }) => {
  return (
    <CustomDialog parentPathname={parentPathname} maxWidth="sm">
      {({ handleClose }) => (
        <TreatmentForm
          type="create"
          hideAnimal={true}
          onClickCancelButton={handleClose}
          onCompleteSubmit={handleClose}
        />
      )}
    </CustomDialog>
  );
};

export default TreatmentCreatePage;
