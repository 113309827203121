// SupplementApplicationCreatePage.jsx
import React from "react";
import CustomDialog from "../../../components/CustomDialog"; // Ajusta la ruta según tu proyecto
import SupplementApplicationForm from "../../HealthControl/SupplementApplication/Forms/SupplementApplicationForm";

/**
 * @component
 * @description Componente que muestra el formulario de aplicación de suplementos dentro de un diálogo
 */
const SupplementApplicationCreatePage = ({ parentPathname }) => {
  return (
    <CustomDialog parentPathname={parentPathname} maxWidth="sm">
      {({ handleClose }) => (
        <SupplementApplicationForm
          type="create"
          hideAnimal={true}
          onClickCancelButton={handleClose}
          onCompleteSubmit={handleClose}
        />
      )}
    </CustomDialog>
  );
};

export default SupplementApplicationCreatePage;
