import moment from "moment";
import "moment/locale/es";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useHistory, useLocation } from "react-router-dom";
import {
  Paper,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@material-ui/core";
import { Calendar, momentLocalizer } from "react-big-calendar";
import EventActions from "../../../redux/actions/event.actions";
import { Event } from "@material-ui/icons";
import CustomMuiTable from "../../../components/CustomMuiTable";
import { columns } from "./constants";
import { eventCalendarRouteOptions } from "../constants";
// import { ROUTES_DICT } from "../../../routes/routesDict";

const localizer = momentLocalizer(moment);
moment.locale("es");

const EventsCalendar = ({ setTitle, setChipList }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const listEvents = useSelector((state) => state.event.list);

  useEffect(() => {
    const fetchData = async () => {
      setTitle("Calendario de Eventos");
      setChipList(eventCalendarRouteOptions(location));

      setIsLoading(true);
      await dispatch(EventActions.list());
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, location, setChipList, setTitle]);

  useEffect(() => {
    if (listEvents.length > 0) {
      const formattedEvents = listEvents.flatMap((event) =>
        event.eventDates.map((date) => ({
          id: event._id,
          title: event.title,
          start: new Date(date),
          end: new Date(date),
          allDay: true,
          type: event.type,
          description: event.description,
          completed: event.completed,
          animal: event.animal,
        }))
      );
      setEvents(formattedEvents);
    }
  }, [listEvents]);

  useEffect(() => {
    const filtered = events.filter((event) =>
      moment(event.start).isSame(selectedDate, "day")
    );
    setFilteredEvents(filtered);
    console.log(filtered);
  }, [selectedDate, events]);

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
  };

  const handleCloseModal = () => {
    setSelectedEvent(null);
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <Paper style={{ padding: 20, height: "100%" }}>
          <Typography variant="h5" gutterBottom>
            Eventos Programados
          </Typography>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            onSelectEvent={handleSelectEvent}
            onSelectSlot={(slotInfo) => handleDateClick(slotInfo.start)}
            selectable
          />
        </Paper>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Paper style={{ padding: 20 }}>
          <Typography variant="h6">Eventos del Día</Typography>
          <CustomMuiTable
            data={filteredEvents}
            columns={columns}
            options={{ selectableRows: "none", search: false }}
            isLoading={isLoading}
          />
        </Paper>
      </Grid>
      <Dialog
        open={Boolean(selectedEvent)}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle style={{ backgroundColor: "#1976d2", color: "white" }}>
          <Box display="flex" alignItems="center" gap={1}>
            <Event fontSize="large" />
            <Typography variant="h6">{selectedEvent?.title}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers style={{ padding: 24 }}>
          {selectedEvent && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" gutterBottom>
                  Detalle del Evento
                </Typography>
                <Typography>
                  <strong>Fecha:</strong>{" "}
                  {moment(selectedEvent.start).format("DD MMM YYYY")}
                </Typography>
                <Typography>
                  <strong>Descripción:</strong>{" "}
                  {selectedEvent.description || "No disponible"}
                </Typography>
                <Typography>
                  <strong>Estado:</strong>{" "}
                  {selectedEvent.completed ? "✅ Completado" : "⏳ Pendiente"}
                </Typography>
              </Grid>
              {selectedEvent.animal && (
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" gutterBottom>
                    Detalle del Animal
                  </Typography>
                  <Typography>
                    <strong>Nombre:</strong>{" "}
                    {selectedEvent.animal?.name || "Sin nombre"}
                  </Typography>
                  <Typography>
                    <strong>ID:</strong>{" "}
                    {selectedEvent.animal?.identifier || "N/A"}
                  </Typography>
                  <Typography>
                    <strong>Color:</strong>{" "}
                    {selectedEvent.animal?.color || "No especificado"}
                  </Typography>
                  <Typography>
                    <strong>Género:</strong>{" "}
                    {selectedEvent.animal?.gender === "FEMALE"
                      ? "Hembra"
                      : "Macho"}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </DialogContent>
        <DialogActions style={{ padding: 16, justifyContent: "center" }}>
          <Button onClick={handleCloseModal} variant="contained" size="large">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default EventsCalendar;
