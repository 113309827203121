import { eventTypeOptions } from "../../../constants";
export const columns = [
  {
    label: "ID. del animal",
    name: "animal",
    options: {
      filter: false,
      customBodyRender: (value) => {
        return value ? value?.identifier || "-" : "-";
      },
    },
  },
  {
    label: "Nombre del animal",
    name: "animal",
    options: {
      filter: false,
      customBodyRender: (value) => {
        return value ? value?.name || "-" : "-";
      },
    },
  },
  {
    label: "Título",
    name: "title",
    options: {
      filter: false,
    },
  },
  {
    label: "Descripción",
    name: "description",
    options: {
      filter: false,
    },
  },
  {
    label: "Tipo",
    name: "type",
    options: {
      filterOptions: {
        names: eventTypeOptions.map((option) => option.name),
      },
      customBodyRender: (value) => {
        const eventType = eventTypeOptions.find(
          (option) => option._id === value
        );
        return eventType ? eventType.name : "-";
      },
    },
  },
  {
    label: "Completado",
    name: "completed",
    options: {
      customBodyRender: (value) => (value ? "Sí" : "No"),
    },
  },
];
