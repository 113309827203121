import ACTION_TYPES from "../types";
import ApiVL from "../../helpers/virtualLabsApi";

const sendReport = (data) => async (dispatch, getState) => {
  const userEmail = getState().auth.user.email;
  dispatch({ type: ACTION_TYPES.REPORT.SEND, payload: true });

  await ApiVL.fetch({
    serviceName: "report/sendExcelReport",
    body: { email: userEmail, data },
    dispatch: dispatch,
    successMessage: "Reporte enviado exitosamente.",
    errorMessage: "Error al enviar el reporte.",
  });

  dispatch({ type: ACTION_TYPES.REPORT.SEND, payload: false });
};

const ReportActions = {
  sendReport,
};

export default ReportActions;
