import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { Check, Error } from "@material-ui/icons";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ButtonFormik from "../../../components/Inputs/ButtonFormik";
import AnimalActions from "../../../redux/actions/animal.actions";
import raceActions from "../../../redux/actions/race.actions";
import uiActions from "../../../redux/actions/ui.actions";
import { useStyles } from "../../../styles";
import * as XLSX from "xlsx";
import BirthActions from "../../../redux/actions/birth.actions";
import serviceActions from "../../../redux/actions/service.actions";
import id from "date-fns/esm/locale/id/index.js";
import _ from "lodash";
import { differenceInMonths } from "date-fns";
/**
 * @component
 * @description Componente, formulario para subida de datos masivos
 * @author Emerson Puma Quispe <emerson.puma@ideascloud.io>
 */

const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

const AnimalBulkForm = ({ onClickCancelButton }) => {
  const classes = useStyles();
  const [csvFile, setCsvFile] = useState();
  const dispatch = useDispatch();
  const RACE_LIST = useSelector((state) => state.race.list);
  const [animalListUploadInfo, setAnimalListUploadInfo] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [csvListLength, setCsvListLength] = useState(0);
  const [animalSucess, setAnimalSucess] = useState(0);
  const [animalError, setAnimalError] = useState(0);
  useEffect(() => {
    if (!RACE_LIST || RACE_LIST.length === 0) {
      dispatch(raceActions.listRace());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRaceId = (raceName) => {
    const raceFinded = RACE_LIST.find((r) => r.name === raceName);
    return _.get(raceFinded, "_id", null);
  };

  const SEXS = {
    MALE: "MALE",
    FEMALE: "FEMALE",
  };
  const SEXS_REVERSED = {
    MACHO: "MALE",
    HEMBRA: "FEMALE",
  };

  const reverseSex = (val) => SEXS_REVERSED[val];
  const isPregnantFunc = (val) => `${val}`.toLowerCase() === "preñada";
  const PREGNANT_STATUS = "PREGNANT";
  const PREGNANT_TYPES = ["IA", "MN", "TE"];

  const colorDic = {
    danger: "#f44336",
    success: "#4caf50",
    warning: "#F0A20A",
  };

  const createBirth = async (additional) => {
    const birth = {
      birthType: "SIMPLE",
      difficulty: "EUTOCICUS",
      retainedPlacenta: false,
      detail: "Carga Masiva",
      observation: "Carga Masiva",
      ...additional,
    };
    await dispatch(BirthActions.create(birth));
  };

  const getMonthsDifference = (birthDate) => {
    if (!birthDate || !_.isDate(birthDate)) return 0;
    return differenceInMonths(new Date(), birthDate);
  };

  const handleSubmit = () => {
    if (csvFile) {
      setAnimalListUploadInfo([]);
      setAnimalSucess(0);
      setAnimalError(0);
      setIsUploading(true);
      dispatch(uiActions.setCanClose(false));
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      let sheet = "";
      let count = 0;
      reader.onload = async (e) => {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? "binary" : "array",
          cellDates: true,
          dateNF: "dd/mm/yyyy",
        });
        let worksheets = {};
        for (const sheetName of wb.SheetNames) {
          if (count === 0) {
            sheet = sheetName;
          }
          count = count + 1;
          worksheets[sheetName] = XLSX.utils.sheet_to_json(
            wb.Sheets[sheetName]
          );
        }

        const data = worksheets[sheet];
        setCsvListLength(worksheets[sheet].length);

        const animalsFound = await dispatch(
          AnimalActions.getIdentifier({ ids: data.map((d) => `${d.arete}`) })
        );
        const animals = [];

        // VALIDATORS
        const errors = data.filter(async (e, index) => {
          let raceArray = [];
          for (let i = 1; i <= 4; i++) {
            if (e[`raza_${i}`] && e[`porcentaje_raza_${i}`]) {
              raceArray.push({
                raceId: getRaceId(e[`raza_${i}`]),
                percentage: e[`porcentaje_raza_${i}`],
              });
            }
          }

          const animal = {
            identifier: `${e.arete}`,
            name: _.get(e, "nombre", ""),
            birthDate: _.get(e, "f_nacimiento"),
            herdDate: _.get(e, "f_ingreso"),
            gender: reverseSex(_.get(e, "sexo")),
            fatherRef: _.get(e, "arete_padre", ""),
            motherRef: _.get(e, "arete_madre", ""),
            registerNumber: _.get(e, "numero_registro", ""),
            baseChildrenLength: _.get(e, "crias", 0),
            baseBirthsLength: _.get(e, "partos", 0),
            baseAbortionsLength: _.get(e, "abortos", 0),
            pregnantDate: null,
            color: _.get(e, "color", ""),
            isDried: _.get(e, "en_producción") !== "SI",
            images: [],
            races: raceArray,
            racialType: _.get(e, "tipo_racial", ""),
            reproductiveStatus: null,
            isReproductor: false,
            category: null,
            extra: {},
          };

          const reproductiveStatus = _.get(e, "estado_rep", ""); // "Preñada" - "Vacía"
          const inProduction = _.get(e, "en_producción") === "SI";
          const isTheAnimalPregnant = isPregnantFunc(reproductiveStatus);
          const pregnantDate = _.get(e, "fec_prenez");
          const pregnantType = _.get(e, "tipo_prenez");
          const lastBirthDate = _.get(e, "f_último_parto");

          const birthDateValue = _.get(e, "f_nacimiento");
          const monthsDiff = getMonthsDifference(birthDateValue);

          let errorMessage = "";

          try {
            const animalExists = _.find(
              animalsFound,
              (a) => a?.identifier === animal.identifier
            );
            if (animalExists) {
              errorMessage = "Arete ya se registro anteriormente";
            } else if (animal.gender === SEXS.MALE) {
              if (monthsDiff < 12) {
                // Macho menor a 12 meses => mismas restricciones de siempre
                if (isTheAnimalPregnant) {
                  errorMessage = "Macho, limpiar el estado preñado";
                } else if (
                  animal.baseChildrenLength !== 0 ||
                  animal.baseBirthsLength !== 0 ||
                  animal.baseAbortionsLength !== 0
                ) {
                  errorMessage = "Macho, limpiar el campos de crias";
                } else if (
                  !_.isNil(lastBirthDate) ||
                  !_.isEmpty(lastBirthDate)
                ) {
                  errorMessage = "Macho, limpiar la fecha de último parto";
                } else if (!_.isNil(pregnantDate) || !_.isEmpty(pregnantDate)) {
                  errorMessage = "Macho, limpiar la fecha de preñez";
                  // } else if (!_.isNil(pregnantType) || !_.isEmpty(pregnantType)) {
                } else if (!_.isNil(pregnantType) && !_.isEmpty(pregnantType)) {
                  errorMessage = "Macho, limpiar el tipo de preñez";
                }
              } else {
                // Macho >=12 meses => se permite crías, partos, abortos
                animal.isReproductor = true; // lo marcamos como reproductor
              }
            } else if (animal.gender === SEXS.FEMALE) {
              // if (isTheAnimalPregnant && !inProduction) {
              //   errorMessage = "Preñada pero no esta en producción";
              // } else
              if (isTheAnimalPregnant && !pregnantDate) {
                errorMessage = "Preñada pero sin fecha de preñez";
              } else if (!isTheAnimalPregnant && pregnantDate) {
                errorMessage = "Indicó fecha de preñez pero no esta preñada";
              } else if (isTheAnimalPregnant && pregnantDate) {
                if (!pregnantType) {
                  errorMessage = "Falta el tipo de preñez";
                } else if (!PREGNANT_TYPES.includes(pregnantType)) {
                  errorMessage = `tipo_prenez solo acepta: ${PREGNANT_TYPES.join(
                    ","
                  )}`;
                }
              } else if (lastBirthDate && !_.isDate(lastBirthDate)) {
                errorMessage =
                  "El formato de fecha de último parto no es correcta ";
              } else if (pregnantDate && _.isDate(pregnantDate)) {
                errorMessage = "El formato de fecha de preñez no es correcta ";
              }
            } else {
              errorMessage = "No se definio el sexo";
            }
          } catch (e) {
            errorMessage = "Error desconocido";
          }
          if (!_.isEmpty(errorMessage)) {
            addMsg(index, animal, errorMessage);
            return true;
          }
          animal.extra = {
            reproductiveStatus,
            inProduction,
            isTheAnimalPregnant,
            pregnantDate,
            pregnantType,
            lastBirthDate,
          };
          animals.push(animal);
          return false;
        });

        if (animals.length !== data.length) {
          dispatch(uiActions.setCanClose(true));
          return;
        }
        // FINISH VALIDATOS

        // TODO START HIM!!
        const promises = animals.map(async (animal, index) => {
          const {
            reproductiveStatus,
            inProduction,
            isTheAnimalPregnant,
            pregnantDate,
            pregnantType,
            lastBirthDate,
          } = animal.extra;

          let animalCreated = null;

          try {
            if (animal.gender === SEXS.MALE) {
              await dispatch(
                AnimalActions.bulk(
                  animal,
                  index === data.length - 1 ? true : false
                )
              );
            } else {
              console.log("cuiskiki");
              animalCreated = await dispatch(
                AnimalActions.bulk(
                  animal,
                  index === data.length - 1 ? true : false
                )
              );

              if (lastBirthDate) {
                await createBirth({
                  animalId: animalCreated[0]._id,
                  birthDate: lastBirthDate,
                });
              }
              if (isTheAnimalPregnant) {
                const serviceType = pregnantType === "IA" ? "AR_IN" : "NA_MO";
                const service = {
                  animalId: animalCreated[0]._id,
                  agribusinessId: animalCreated[0].agribusinessId,
                  observation: "CARGA MASIVA",
                  serviceDate: pregnantDate,
                  serviceType,
                };
                await dispatch(serviceActions.create(service));
                await dispatch(
                  AnimalActions.bulkUpdate({
                    ...animalCreated[0],
                    isServed: false,
                    isPregnant: true,
                    isDried: false,
                    reproductiveStatus: PREGNANT_STATUS,
                    pregnantDate: pregnantDate,
                  })
                );
              }
            }
            addMsg(index, animal, null, "success");
            setAnimalSucess((state) => state + 1);
          } catch (e) {
            addMsg(index, animal, e?.message || "Error desconocido", "danger");
            setAnimalError((animalError) => animalError + 1);
          }
        });

        Promise.all(promises).then(() => {
          dispatch(uiActions.setCanClose(true));
          dispatch(uiActions.setIsUploaded(true));
        });
        // setIsUploading(false)
      };

      if (rABS) reader.readAsBinaryString(csvFile);
      else reader.readAsArrayBuffer(csvFile);
    } else {
      dispatch(
        uiActions.showSnackbar("No has subido ningun archivo", "warning")
      );
    }
  };

  const addMsg = (index, animal, message, uploadType = "warning") => {
    setAnimalListUploadInfo((oldArray) => [
      ...oldArray,
      { ...animal, error: message, uploadType: uploadType, excelIndex: index },
    ]);
    // setAnimalError((animalError) => animalError + 1);
    if (uploadType === "danger" || message) {
      setAnimalError((animalError) => animalError + 1);
    }
  };

  const fileData = (csvFile) => {
    if (csvFile) {
      return (
        <div style={{ paddingLeft: "0.5rem" }}>
          <p>File Name: {csvFile.name}</p>
        </div>
      );
    } else {
      return (
        <>
          {/* <div style={{ paddingLeft: "0.5rem" }}>
            <h4>Elija antes de presionar el botón Cargar</h4>
          </div> */}
          <div style={{ paddingLeft: "0.5rem" }}>
            <h4>Elija antes de presionar el botón Guardar</h4>
          </div>
        </>
      );
    }
  };
  return (
    <>
      <Typography variant={"subtitle1"}>Registro masivo</Typography>
      <Typography variant={"subtitle2"}>
        Para descargar el modelo de carga masiva presione{" "}
        {/* <a href="https://contigo-files.s3.amazonaws.com/static/excel_contigo.xlsx"> */}
        {/* <a href="https://60e347443ea9e50009072aa0.projects.ideascloud.io/file-uploads/637694c1df4ba50008b78562"> */}
        <a href="https://contigo-files.s3.us-east-1.amazonaws.com/static/excel_contigo_example.xlsx">
          <strong>AQUÍ</strong>
        </a>
      </Typography>
      <Grid container spacing={1}>
        <Button
          component="label"
          className={classes.baseBtn}
          endIcon={false && <CircularProgress size={20} />}
          variant="contained"
        >
          Subir XLSX
          <input
            accept=".xlsx"
            hidden
            name="csvFile"
            className={classes.fileInput}
            onChange={(e) => {
              if (
                e.currentTarget.files[0]?.type === "application/vnd.ms-excel" ||
                e.currentTarget.files[0]?.type ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ) {
                setCsvFile(e.currentTarget.files[0]);
              } else {
                dispatch(
                  uiActions.showSnackbar(
                    "El formato del archivo no es compatible",
                    "error",
                    4000
                  )
                );
              }
            }}
            type="file"
          />
        </Button>
        {fileData(csvFile)}
      </Grid>
      <Grid container item xs={12} spacing={1}>
        {isUploading && (
          <>
            <Grid item xs={12}>
              <LinearProgressWithLabel
                value={(animalListUploadInfo.length / csvListLength) * 100}
              />
              {`${animalSucess}/${csvListLength} registros subidos`}
            </Grid>

            <Grid
              container
              item
              xs={12}
              spacing={1}
              style={{ marginTop: "0.5rem" }}
            >
              <Typography variant={"subtitle2"}>
                {animalError} registros con errores
              </Typography>
              <Grid
                container
                item
                xs={12}
                spacing={1}
                className={classes.chipContainer}
              >
                {animalListUploadInfo.map((animal, index) => (
                  <Grid item xs={12} key={animal.excelIndex}>
                    <Chip
                      icon={
                        !animal?.error ? (
                          <Check className={`${classes.largeChip}__icon`} />
                        ) : (
                          <Error className={`${classes.largeChip}__icon`} />
                        )
                      }
                      className={clsx(
                        classes.largeChip,
                        animal
                          ? `${classes.largeChip}--${animal.uploadType}`
                          : `${classes.largeChip}--${animal.uploadType}`
                      )}
                      style={{
                        backgroundColor: colorDic[animal.uploadType],
                      }}
                      label={
                        !animal.error
                          ? `fila ${animal.excelIndex + 2}, ${
                              animal.identifier
                            }: Exitoso` // plus 2 because of the excel header and the 0 index
                          : `fila ${animal.excelIndex + 2}, ${
                              animal.identifier
                            }: ${
                              animal?.error
                                ? animal?.error
                                : "Error desconocido"
                            }`
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        container
        justifyContent={"flex-end"}
        style={{ gap: "0.5rem" }}
        xs={12}
      >
        <Grid item xs={2}>
          <ButtonFormik
            onClick={() => {
              onClickCancelButton();
            }}
            xs={2}
            label={!isUploading ? "Cancelar" : "Cerrar"}
            type="button"
          />
        </Grid>
        {!isUploading && (
          <Grid item xs={2}>
            <ButtonFormik
              onClick={handleSubmit}
              xs={2}
              label="Guardar"
              type="submit"
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AnimalBulkForm;
