import ApiVL from "../helpers/virtualLabsApi";

// Código anterior como referencia:
// IdeasCloudApi.fetch("countryList", {});
// IdeasCloudApi.fetch("regionListByCountry", { countryId });
// IdeasCloudApi.fetch("districtListByRegion", { regionId });

class LocationService {
  // Nueva versión usando ApiVL:

  async getCountryList() {
    try {
      const response = await ApiVL.fetch({
        serviceName: "country/List",
        body: {},
      });
      return response;
    } catch (error) {
      console.error(error);
      const rejectBody = {
        message: error?.message || "Error desconocido",
      };
      throw rejectBody;
    }
  }

  async getRegionListByCountry(countryId) {
    try {
      const response = await ApiVL.fetch({
        serviceName: "region/ListByCountry",
        body: { countryId },
      });
      return response;
    } catch (error) {
      console.error(error);
      const rejectBody = {
        message: error?.message || "Error desconocido",
      };
      throw rejectBody;
    }
  }

  async getDistrictListByRegion(regionId) {
    try {
      const response = await ApiVL.fetch({
        serviceName: "district/ListByRegion",
        body: { regionId },
      });
      return response;
    } catch (error) {
      console.error(error);
      const rejectBody = {
        message: error?.message || "Error desconocido",
      };
      throw rejectBody;
    }
  }
}

export default new LocationService();
