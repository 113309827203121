import ACTION_TYPES from "../types";
import ApiVL from "../../helpers/virtualLabsApi";

const prefixRoute = "calendar";

const list = () => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/ListByAgribusiness`,
    body: { agribusinessId: agribusiness?._id },
  });
  dispatch({ type: ACTION_TYPES.EVENT.RETRIEVE, payload: response });
};

const get = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/GetById`,
    body: data,
  });
  dispatch({ type: ACTION_TYPES.EVENT.UPDATE_CURRENT, payload: response });
  return response;
};

const clearCurrent = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPES.EVENT.UPDATE_CURRENT, payload: null });
};

const create = (data) => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Create`,
    body: { ...data, agribusinessId: agribusiness._id },
    dispatch: dispatch,
    successMessage: "Evento registrado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({
    type: ACTION_TYPES.EVENT.CREATE,
    payload: response,
  });
  dispatch(list());
  return response;
};

const update = (data) => async (dispatch) => {
  await ApiVL.fetch({
    serviceName: `${prefixRoute}/Update`,
    body: data,
    dispatch: dispatch,
    successMessage: "Evento actualizado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });
  dispatch({ type: ACTION_TYPES.EVENT.UPDATE_CURRENT, payload: data });
  dispatch(list());
};

const remove = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Delete`,
    body: { ...data },
    dispatch: dispatch,
    successMessage: "Evento eliminado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });
  dispatch({ type: ACTION_TYPES.EVENT.DELETE, payload: response });
  dispatch(list());
};

const EVENTActions = {
  list,
  get,
  create,
  update,
  clearCurrent,
  remove,
};

export default EVENTActions;
