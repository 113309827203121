import React from "react";
import { Facebook } from "@material-ui/icons";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import Logo from "../../components/Logo";
import RegisterCard from "../../components/RegisterCard";
import { Formik } from "formik";
import * as yup from "yup";
import TextFieldFormik from "../../components/Inputs/TextFieldFormik";
import { Link, useHistory } from "react-router-dom";
import googleBtn from "../../assets/images/google.png";
import { useDispatch } from "react-redux";
import PasswordFieldFormik from "../../components/Inputs/PasswordFieldFormik";
import { ROUTES_DICT } from "../../routes/routesDict";
import AuthActions from "../../redux/actions/auth.actions";
import { useGoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

function LoginPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  // Estado para el modal
  const [openModal, setOpenModal] = useState(false);

  const validationSchema = yup.object({
    email: yup
      .string("Ingresa tu correo eletrónico.")
      .email("Ingrese un correo válido.")
      .required("El correo electrónico es requerido."),
    password: yup
      .string("Ingresa tu contraseña")

      .required("La contraseña es requerida."),
  });

  const onSuccess = (res) => {
    console.log(res);
    const values = {
      email: res.profileObj.email,
      firstName: res.profileObj.givenName,
      lastName: res.profileObj.familyName,
    };
    dispatch(AuthActions.loginWithGoogle(values)).then((farm) => {
      if (farm) {
        history.push(ROUTES_DICT.animal.list);
      } else {
        history.push(ROUTES_DICT.setup);
      }
    });
  };

  const onFailure = (res) => {};

  const clientId = process.env.REACT_APP_GOOGLE_ENV;
  const clientIdFb = process.env.REACT_APP_FACEBOOK_ENV;
  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    accessType: "offline",
  });

  const onResponseFB = (res) => {
    const values = {
      email: res.email,
      firstName: res.name,
      lastName: "",
    };
    dispatch(AuthActions.loginWithGoogle(values)).then((farm) => {
      if (farm) {
        history.push(ROUTES_DICT.animal.list);
      } else {
        history.push(ROUTES_DICT.setup);
      }
    });
  };

  const handleModalClose = () => {
    setOpenModal(false);
    history.push(ROUTES_DICT.recoverPassword);
  };

  const onSubmitForm = (values, actions) => {
    dispatch(AuthActions.login(values))
      .then((farm) => {
        if (farm) {
          history.push(ROUTES_DICT.animal.list);
        } else {
          history.push(ROUTES_DICT.setup);
        }
      })
      .catch((error) => {
        // console.log("Error durante login:", error);
        if (error?.message === "El usuario no está migrado") {
          setOpenModal(true);
        } else {
          // Manejo de otros errores
          actions.setSubmitting(false);
        }
      });
  };

  const LoginForm = ({
    handleChange,
    handleSubmit,
    isSubmitting,
    resetForm,
    values,
    errors,
    touched,
  }) => {
    return (
      <form onSubmit={handleSubmit} className={classes.form}>
        <Grid container spacing={1}>
          <TextFieldFormik
            name="email"
            type="text"
            label="Correo"
            onChange={handleChange}
          ></TextFieldFormik>
          <PasswordFieldFormik
            label="Contraseña"
            name="password"
            onChange={handleChange}
          ></PasswordFieldFormik>
          <Grid item xs={12}>
            <Button
              className={classes.loginBtn}
              disabled={isSubmitting}
              type="submit"
            >
              Iniciar Sesión
            </Button>
          </Grid>
        </Grid>
        <Grid container className={classes.linksContainer}>
          <Grid item>
            <Typography variant="body2">
              <Link className={classes.link} to={ROUTES_DICT.recoverPassword}>
                ¿Olvidaste tu contraseña?
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">
              <Link className={classes.registerLink} to={ROUTES_DICT.register}>
                Regístrate
              </Link>
            </Typography>
          </Grid>
          {/* <Grid item xs={12}>
            <Button
              className={classes.googleBtn}
              startIcon={<img src={googleBtn} alt={"Google"} />}
              onClick={() => {
                console.log("prueba login");
                signIn();
              }}
            >
              <Typography align={"center"} className={classes.googleBtnText}>
                Iniciar sesión con Google
              </Typography>
            </Button>
          </Grid> */}
          {/* <Grid item xs={12}>
            <FacebookLogin
              appId={clientIdFb}
              fields="name,email,picture"
              callback={onResponseFB}
              render={(renderProps) => (
                <Button
                  className={classes.googleBtn}
                  startIcon={<Facebook className={classes.fbIcon} />}
                  onClick={renderProps.onClick}
                >
                  <Typography
                    align={"center"}
                    className={classes.googleBtnText}
                  >
                    Iniciar sesión con Facebook
                  </Typography>
                </Button>
              )}
            />
          </Grid> */}
        </Grid>
      </form>
    );
  };

  return (
    <Container>
      <RegisterCard register>
        <Logo customClasses={classes.logo} />
        <Grid item className={classes.titleContainer}>
          <Typography align={"center"} className={classes.title}>
            Iniciar Sesión
          </Typography>
          <Typography
            variant={"caption"}
            gutterBottom
            align={"center"}
            className={classes.subtitle}
          >
            Gestiona tu finca desde el panel de usuario.
          </Typography>
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={onSubmitForm}
            validationSchema={validationSchema}
          >
            {(props) => <LoginForm {...props} />}
          </Formik>
        </Grid>
      </RegisterCard>
      <Dialog
        open={openModal}
        onClose={handleModalClose}
        classes={{
          paper: classes.modalPaper,
        }}
      >
        <DialogTitle className={classes.modalTitle}>
          ¡Actualización requerida!
        </DialogTitle>
        <DialogContent className={classes.modalContent}>
          <DialogContentText className={classes.modalText}>
            Para continuar usando nuestros servicios, debe actualizar su
            contraseña.
          </DialogContentText>
          <DialogContentText className={classes.modalSecondaryText}>
            Este cambio es obligatorio para garantizar la seguridad de su
            cuenta.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalActions}>
          <Button
            onClick={handleModalClose}
            className={classes.modalButton}
            variant="contained"
          >
            Actualizar Contraseña
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default LoginPage;
