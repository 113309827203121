import ApiVL from "../helpers/virtualLabsApi";

const prefixRouteService = "service";

const serviceCreate = async (data) => {
  try {
    const response = await ApiVL.fetch({
      serviceName: `${prefixRouteService}/Create`,
      body: data,
      successMessage: "Servicio creado exitosamente.",
      errorMessage: "Error al crear el servicio, intente nuevamente.",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const serviceListByAgribusiness = async (data) => {
  try {
    const response = await ApiVL.fetch({
      serviceName: `${prefixRouteService}/ListByAgribusiness`,
      body: data,
      successMessage: "Servicios obtenidos exitosamente.",
      errorMessage: "Error al obtener los servicios, intente nuevamente.",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const serviceGetById = async (data) => {
  try {
    const response = await ApiVL.fetch({
      serviceName: `${prefixRouteService}/GetById`,
      body: data,
      successMessage: "Servicio obtenido exitosamente.",
      errorMessage: "Error al obtener el servicio, intente nuevamente.",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const serviceUpdate = async (data) => {
  try {
    const response = await ApiVL.fetch({
      serviceName: `${prefixRouteService}/Update`,
      body: data,
      successMessage: "Servicio actualizado exitosamente.",
      errorMessage: "Error al actualizar el servicio, intente nuevamente.",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const serviceDelete = async (data) => {
  try {
    const response = await ApiVL.fetch({
      serviceName: `${prefixRouteService}/Delete`,
      body: data,
      successMessage: "Servicio eliminado exitosamente.",
      errorMessage: "Error al eliminar el servicio, intente nuevamente.",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const ServiceService = {
  serviceListByAgribusiness,
  serviceGetById,
  serviceCreate,
  serviceUpdate,
  serviceDelete,
};

export default ServiceService;
