import React, { useState, useEffect } from "react";
import {
  useHistory,
  useLocation,
  useParams,
  generatePath,
} from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useStyles } from "../styles";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import TableButtons from "../../../components/TableButtons";
import CustomMuiTable from "../../../components/CustomMuiTable";

import AddEvent from "../AddEvent";
import { columns } from "./constants";
import { eventRouteOptions } from "../constants";
import { ROUTES_DICT } from "../../../routes/routesDict";

import EventActions from "../../../redux/actions/event.actions";

const EventsPageList = ({ children, setTitle, setChipList }) => {
  const history = useHistory();
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const listEvents = useSelector(
    (state) =>
      state.event.list.filter((e) =>
        params._id ? e.animalId === params._id : e.animalId
      ),
    shallowEqual
  );

  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setTitle("Eventos del Calendario");
      setChipList(eventRouteOptions(location));

      setIsLoading(true);
      await dispatch(EventActions.list());
      setIsLoading(false);
    };

    fetchData();
  }, [dispatch, location, setChipList, setTitle]);

  const options = {
    selectableRows: "none",
    searchText,
    search: false,
    sortOrder: {
      name: "title",
      direction: "asc",
    },
  };

  const actionColumn = {
    label: "Acciones",
    name: "actions",
    options: {
      searchable: false,
      filter: false,
      sort: false,
      customBodyRenderLite: (dataIndex) => {
        return (
          <TableButtons
            onClickDeleteButton={() => {
              history.push(
                generatePath(ROUTES_DICT.event.delete, {
                  ...params,
                  _id: listEvents[dataIndex]._id,
                })
              );
            }}
            onClickEditButton={() => {
              history.push(
                generatePath(ROUTES_DICT.event.update, {
                  ...params,
                  _id: listEvents[dataIndex]._id,
                })
              );
            }}
          />
        );
      },
    },
  };

  return (
    <Grid item container xs={12}>
      <AddEvent searchText={searchText} setSearchText={setSearchText} />
      <Grid item xs={12} className={classes.registerContainer}>
        <CustomMuiTable
          data={listEvents}
          columns={[...columns, actionColumn]}
          options={options}
          isLoading={isLoading}
        />
      </Grid>
      {children()}
    </Grid>
  );
};

export default EventsPageList;
