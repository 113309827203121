// SampleCollectionCreatePage.jsx
import React from "react";
import CustomDialog from "../../../components/CustomDialog";
import SampleCollectionForm from "../../HealthControl/SampleCollection/Forms/SampleCollectionForm";

/**
 * @component
 * @description Componente que contiene el formulario de recolección de muestras dentro de un diálogo
 */
const SampleCollectionCreatePage = ({ parentPathname }) => {
  return (
    <CustomDialog parentPathname={parentPathname} maxWidth="sm">
      {({ handleClose }) => (
        <SampleCollectionForm
          type="create"
          hideAnimal={true}
          onClickCancelButton={handleClose}
          onCompleteSubmit={handleClose}
        />
      )}
    </CustomDialog>
  );
};

export default SampleCollectionCreatePage;
