import ApiVL from "../helpers/virtualLabsApi";

const prefixRoute = "region";

class RegionService {
  // Código anterior (usando window.icAPI):
  // const getAll = () => {
  //   return new Promise((resolve, reject) => {
  //     window.icAPI.callService("regionList", {}, function (error, response) {
  //       if (!error) {
  //         resolve(response.responseJSON);
  //       } else {
  //         reject(response);
  //       }
  //     });
  //   });
  // };

  // Nueva versión (usando ApiVL con prefixRoute):
  async getAll() {
    try {
      const response = await ApiVL.fetch({
        serviceName: `${prefixRoute}/List`,
        body: {},
      });
      return response;
    } catch (error) {
      console.log(error);
      const rejectBody = {
        message: error?.message || "Error desconocido",
      };
      throw rejectBody;
    }
  }

  async listByCountry(countryId) {
    try {
      const response = await ApiVL.fetch({
        serviceName: `${prefixRoute}/ListByCountry`,
        body: { countryId },
      });
      return response;
    } catch (error) {
      console.log(error);
      const rejectBody = {
        message: error?.message || "Error desconocido",
      };
      throw rejectBody;
    }
  }
}

export default new RegionService();

// const RegionService = {
//   getAll,
// };

// export default RegionService;
