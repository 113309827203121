import ApiVL from "../helpers/virtualLabsApi";

const prefixRoute = "geneticStock";

class GeneticStockService {
  // const geneticStockCreate = (data) => {
  //   return new Promise((resolve, reject) => {
  //     window.icAPI.callService(
  //       "geneticStockCreateV2",
  //       data,
  //       function (error, response) {
  //         if (!error) {
  //           resolve(response.responseJSON);
  //         } else {
  //           reject(response);
  //         }
  //       }
  //     );
  //   });
  // };

  async geneticStockCreate(data) {
    try {
      const response = await ApiVL.fetch({
        serviceName: `${prefixRoute}/Create`,
        body: data,
      });
      return response;
    } catch (error) {
      console.log(error);
      const rejectBody = {
        message: error?.message || "Error desconocido",
      };
      throw rejectBody;
    }
  }

  // const geneticStockListByAgribusiness = (data) => {
  //   return new Promise((resolve, reject) => {
  //     window.icAPI.callService(
  //       "geneticStockListByAgribusiness",
  //       data,
  //       function (error, response) {
  //         if (!error) {
  //           resolve(response.responseJSON);
  //         } else {
  //           reject(response);
  //         }
  //       }
  //     );
  //   });
  // };

  async geneticStockListByAgribusiness(data) {
    try {
      const response = await ApiVL.fetch({
        serviceName: `${prefixRoute}/ListByAgribusiness`,
        body: data,
      });
      return response;
    } catch (error) {
      console.log(error);
      const rejectBody = {
        message: error?.message || "Error desconocido",
      };
      throw rejectBody;
    }
  }

  // const geneticStockGetById = (data) => {
  //   return new Promise((resolve, reject) => {
  //     window.icAPI.callService(
  //       "geneticStockGetById",
  //       data,
  //       function (error, response) {
  //         if (!error) {
  //           resolve(response.responseJSON);
  //         } else {
  //           reject(response);
  //         }
  //       }
  //     );
  //   });
  // };

  async geneticStockGetById(data) {
    try {
      const response = await ApiVL.fetch({
        serviceName: `${prefixRoute}/GetById`,
        body: data,
      });
      return response;
    } catch (error) {
      console.log(error);
      const rejectBody = {
        message: error?.message || "Error desconocido",
      };
      throw rejectBody;
    }
  }

  // const geneticStockUpdate = (data) => {
  //   return new Promise((resolve, reject) => {
  //     window.icAPI.callService(
  //       "geneticStockUpdate",
  //       data,
  //       function (error, response) {
  //         if (!error) {
  //           resolve(response.responseJSON);
  //         } else {
  //           reject(response);
  //         }
  //       }
  //     );
  //   });
  // };

  async geneticStockUpdate(data) {
    try {
      const response = await ApiVL.fetch({
        serviceName: `${prefixRoute}/Update`,
        body: data,
      });
      return response;
    } catch (error) {
      console.log(error);
      const rejectBody = {
        message: error?.message || "Error desconocido",
      };
      throw rejectBody;
    }
  }

  // const geneticStockDelete = (data) => {
  //   return new Promise((resolve, reject) => {
  //     window.icAPI.callService(
  //       "geneticStockDelete",
  //       data,
  //       function (error, response) {
  //         if (!error) {
  //           resolve(response.responseJSON);
  //         } else {
  //           reject(response);
  //         }
  //       }
  //     );
  //   });
  // };

  async geneticStockDelete(data) {
    try {
      const response = await ApiVL.fetch({
        serviceName: `${prefixRoute}/Delete`,
        body: data,
      });
      return response;
    } catch (error) {
      console.log(error);
      const rejectBody = {
        message: error?.message || "Error desconocido",
      };
      throw rejectBody;
    }
  }
}

export default new GeneticStockService();
