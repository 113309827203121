import React from "react";
import CustomDialog from "../../../components/CustomDialog";
import VaccinationForm from "../../HealthControl/Vaccination/Forms/VaccinationForm";

/**
 * @component
 * @description Componente que muestra un diálogo (modal) para crear
 * un registro de vacunación asignado a un animal específico.
 */
const VaccinationCreatePage = ({ parentPathname }) => {
  return (
    <CustomDialog parentPathname={parentPathname} maxWidth="sm">
      {(props) => (
        <VaccinationForm
          type="create"
          hideAnimal={true}
          onClickCancelButton={props.handleClose}
          onCompleteSubmit={props.handleClose}
        />
      )}
    </CustomDialog>
  );
};

export default VaccinationCreatePage;
