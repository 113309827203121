import { ROUTES_DICT } from "../../routes/routesDict";

export const eventRouteOptions = (location) => [
  {
    key: "Inicio",
    path: ROUTES_DICT.event.list,
    icon: "home",
  },
  {
    key: "Agregar",
    path: ROUTES_DICT.event.create,
    icon: "add",
  },
];

export const eventCalendarRouteOptions = (location) => [
  {
    key: "Inicio",
    path: ROUTES_DICT.event.calendar,
    icon: "home",
  },
  {
    key: "Agregar",
    path: ROUTES_DICT.event.create_calendar,
    icon: "add",
  },
];
