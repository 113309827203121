import React, { useEffect, useState } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dialog } from "@material-ui/core";
import { useStyles } from "./styles";

// Ajusta las rutas de import según tu proyecto
import { ROUTES_DICT } from "../../routes/routesDict";
import AgribusinessActions from "../../redux/actions/agribusiness.actions";
import { farmActions } from "../../redux/actions/farm.actions";
import UserActions from "../../redux/actions/user.actions";

export default function AutomatedSetupPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);

  // Estado para manejar el paso actual (mostrar mensajes)
  const [step, setStep] = useState(0);

  // Al montar el componente, iniciamos el proceso automático
  useEffect(() => {
    const createFarmAndAgribusiness = async () => {
      try {
        // 1. Creación automática de la Hacienda (Farm)
        setStep(1); // Para mostrar "Se está creando tu hacienda..."
        const farmData = {
          ownerId: user._id, // Se obtiene del estado auth, igual que en el formulario
          name: "Hacienda 1", // Texto por defecto
          landLord: "Usuario 1", // Texto por defecto
          nit: "", // RUC/DNI/NIT en blanco
          countryId: "6266e30daca0d30008cdb7c7", // Perú, por defecto
          regionId: "6266e30edd65be0008ca75f9", // Arequipa  por defecto
          districtId: "6266e31066d86800087de6fb", // Arequipa por defecto
          address: "casa 1", // Texto por defecto
          phoneNumber: "", // En blanco
          currencyId: "6266dd78bdfc8fa2ecbbe453", // Moneda peruana
          weightUnit: "KILOGRAMS",
          areaUnit: "HECTARES",
          capacityUnit: "KILOGRAMS",
        };

        // Llamamos a la función que crea la Hacienda en backend
        const farmResponse = await dispatch(farmActions.create(farmData));
        console.log("farmResponse:", farmResponse);

        // 2. Creación automática del Agronegocio
        setStep(2); // Mostrar "Estamos configurando tu agronegocio..."

        let updatedUser = null;
        let attempts = 0;

        while (!updatedUser?.farmId && attempts < 10) {
          // Intentamos 10 veces máximo
          await new Promise((res) => setTimeout(res, 500)); // Espera 500ms antes de intentar de nuevo
          updatedUser = await dispatch(UserActions.get({ _id: user._id }));
          console.log("Usuario actualizado:", updatedUser);
          attempts++;
        }

        if (!updatedUser?.farmId) {
          throw new Error(
            "El usuario no recibió un farmId después de crear la hacienda."
          );
        }

        setStep(3); // Paso final (opcional)

        const agribusinessData = {
          countryId: "6266e30daca0d30008cdb7c7", // Perú, por defecto
          regionId: "6266e30edd65be0008ca75f9", // Arequipa  por defecto
          districtId: "6266e31066d86800087de6fb", // Arequipa por defecto
          address: "casa 1",
          averageRainfall: "",
          isBreeding: 7,
          isHeifer: 15,
          meatCost: 0,
          meatPrice: 0,
          meatUnit: "KILOGRAMS",
          milkCost: 0,
          milkPrice: 0,
          milkUnit: "LITERS",
          milkingNumber: "2",
          milkingType: "AUTOMATIC",
          name: "Agro 1",
          phoneNumber: "",
          reproductiveManagement: "DM_&AI&_ET",
          system: "SEMI_INTENSIVE",
          objectiveFarmOptions: "",
          type: "",
          // farmId: farmResponse._id,
        };

        // 3. Usamos la acción de Redux para crear el agronegocio
        await dispatch(AgribusinessActions.create(agribusinessData));

        setStep(4);
        // 4. Redirigir al usuario a la pantalla de gráficos
        history.push(ROUTES_DICT.graphics);
      } catch (error) {
        console.error("Error creando hacienda o agronegocio:", error);
        // Podrías manejar la redirección a una pantalla de error si lo deseas
      }
    };

    createFarmAndAgribusiness();
  }, [dispatch, user, history]);

  // Lógica para mostrar mensajes en pantalla según el paso
  const renderMessage = () => {
    switch (step) {
      case 1:
        return "Se está creando tu hacienda...";
      case 2:
        return "Estamos configurando tu agronegocio...";
      case 3:
        return "¡Proceso finalizado! Redirigiendo...";
      default:
        return "Iniciando proceso de configuración...";
    }
  };

  return (
    <>
      <Dialog
        open={true}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paperFullWidth: classes.modal }}
      >
        <div style={{ textAlign: "center", marginTop: "2rem" }}>
          <CircularProgress />
          <Typography variant="h6" style={{ marginTop: "1rem" }}>
            {renderMessage()}
          </Typography>
        </div>
      </Dialog>
    </>
  );
}
