import CustomDialog from "../../../components/CustomDialog";
import EventForm from "../Forms/EventForm";

/**
 * @component
 * @description Componente, modal que contiene el formulario para agregar un nuevo evento en el calendario.
 */

const EventCreatePage = ({ parentPathname }) => {
  return (
    <CustomDialog parentPathname={parentPathname}>
      {(props) => (
        <EventForm
          type="create"
          onClickCancelButton={props.handleClose}
          onCompleteSubmit={props.handleClose}
        />
      )}
    </CustomDialog>
  );
};

export default EventCreatePage;
