import ApiVL from "../helpers/virtualLabsApi";

const prefixRouteUser = "user";

const userLogin = async (email, password) => {
  try {
    const response = await ApiVL.fetch({
      serviceName: `${prefixRouteUser}/Login`,
      body: { email, password },
      successMessage: "Inicio de sesión exitoso.",
      errorMessage: "Error al iniciar sesión, intente nuevamente.",
    });
    localStorage.setItem("user", JSON.stringify(response));
    return response;
  } catch (error) {
    throw error;
  }
};

const userRegister = async (userData) => {
  try {
    const response = await ApiVL.fetch({
      serviceName: `${prefixRouteUser}/Register`,
      body: userData,
      successMessage: "Registro exitoso.",
      errorMessage: "Error al registrarse, intente nuevamente.",
    });
    localStorage.setItem("user", JSON.stringify(response));
    return response;
  } catch (error) {
    throw error;
  }
};

const userUpdate = async (data) => {
  try {
    const response = await ApiVL.fetch({
      serviceName: `${prefixRouteUser}/Update`,
      body: data,
      successMessage: "Datos actualizados satisfactoriamente.",
      errorMessage: "Error al actualizar datos, intente nuevamente.",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const sendVerificationEmail = async ({ email }) => {
  try {
    const response = await ApiVL.fetch({
      serviceName: `${prefixRouteUser}/SendVerificationEmail`,
      body: { email },
      successMessage: "Correo de verificación enviado.",
      errorMessage:
        "Error al enviar correo de verificación, intente nuevamente.",
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const UserService = {
  userLogin,
  userRegister,
  sendVerificationEmail,
  userUpdate,
};

export default UserService;
