import ACTION_TYPES from "../types";
import ApiVL from "../../helpers/virtualLabsApi";

const prefixRoute = "animal";

const list = () => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/ListByAgribusiness`,
    body: { agribusinessId: agribusiness?._id },
  });
  console.log(`Ya contestó vivos ${response.length}`);

  dispatch({ type: ACTION_TYPES.ANIMAL.RETRIEVE, payload: response });
};

const listDeads = () => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/ListDeadsByAgribusiness`,
    body: { agribusinessId: agribusiness?._id },
  });
  console.log(`Ya contestó muertos ${response.length}`);

  dispatch({ type: ACTION_TYPES.ANIMAL.RETRIEVE_DEADS, payload: response });
};

const get = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/GetById`,
    body: data,
  });
  dispatch({ type: ACTION_TYPES.ANIMAL.UPDATE_CURRENT, payload: response });
  return response;
};

const getIdentifier = (data) => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/GetByIdSimple`,
    // body: data,
    body: { ...data, agribusinessId: agribusiness._id },
  });
  dispatch({ type: ACTION_TYPES.ANIMAL.UPDATE_CURRENT, payload: response });
  return response;
};

const clearCurrent = (data) => async (dispatch) => {
  dispatch({ type: ACTION_TYPES.ANIMAL.UPDATE_CURRENT, payload: null });
};

const bulk = (data, end) => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  const response = await ApiVL.fetchBulk({
    // serviceName: `${prefixRoute}/CreateBulkAnimal`,
    serviceName: `${prefixRoute}/Create`,
    body: { ...data, agribusinessId: agribusiness._id },
    dispatch: end ? dispatch : undefined,
    successMessage: "Carga masiva completada satisfactoriamente.",
  });

  dispatch({
    type: ACTION_TYPES.ANIMAL.CREATE,
    payload: response,
  });
  // dispatch(list());
  return response;
};

const create = (data) => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Create`,
    body: { ...data, agribusinessId: agribusiness._id },
    dispatch: dispatch,
    successMessage: "Animal registrado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({
    type: ACTION_TYPES.ANIMAL.CREATE,
    payload: response,
  });
  dispatch(list());
  return response;
};

const bulkUpdate = (data) => async (dispatch) => {
  await ApiVL.fetch({
    serviceName: `${prefixRoute}/UpdateBulkAnimal`,
    body: data,
    dispatch: dispatch,
    successMessage: "Animal actualizado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({ type: ACTION_TYPES.ANIMAL.UPDATE_CURRENT, payload: data });
  dispatch(list());
};

const update = (data) => async (dispatch) => {
  await ApiVL.fetch({
    serviceName: `${prefixRoute}/Update`,
    body: data,
    dispatch: dispatch,
    successMessage: "Animal actualizado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({ type: ACTION_TYPES.ANIMAL.UPDATE_CURRENT, payload: data });
  dispatch(list());
};

const deleteAnimal = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Delete`,
    body: { ...data },
    dispatch: dispatch,
    successMessage: "Animal eliminado exitosamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({ type: ACTION_TYPES.ANIMAL.DELETE, payload: response });
  dispatch(list());
};

const AnimalActions = {
  bulk,
  list,
  listDeads,
  get,
  create,
  update,
  bulkUpdate,
  clearCurrent,
  deleteAnimal,
  getIdentifier,
};

export default AnimalActions;
