import { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import EventActions from "../../../redux/actions/event.actions";
import EventForm from "../Forms/EventForm";
import CustomDialog from "../../../components/CustomDialog";

/**
 * @component
 * @description Componente, modal que contiene el formulario para editar un evento en el calendario.
 */

const EventUpdatePage = ({ parentPathname }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const currentEvent = useSelector((state) => state.event.current);

  useEffect(() => {
    if (!currentEvent || currentEvent._id !== params._id) {
      dispatch(EventActions.get({ _id: params._id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <CustomDialog parentPathname={parentPathname}>
      {(props) => (
        <>
          {currentEvent &&
            currentEvent._id &&
            currentEvent._id === params._id && (
              <EventForm
                type="update"
                initValues={currentEvent}
                onClickCancelButton={props.handleClose}
                onCompleteSubmit={props.handleClose}
              />
            )}
        </>
      )}
    </CustomDialog>
  );
};

export default EventUpdatePage;
