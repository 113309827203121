import React, { useState, useEffect } from "react";
import {
  useHistory,
  useLocation,
  useParams,
  generatePath,
} from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useStyles } from "../styles";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import TableButtons from "../../../../components/TableButtons";
import CustomMuiTable from "../../../../components/CustomMuiTable";

import AddTreatment from "../AddTreatment";

import { columns } from "./constants";
import { treatmentRouteOptions } from "../constants";
import { ROUTES_DICT } from "../../../../routes/routesDict";

import TreatmentActions from "../../../../redux/actions/treatment.actions";

const TreatmentPageList = ({ children, setTitle, setChipList }) => {
  const history = useHistory();
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const listTreatment = useSelector(
    (state) =>
      state.treatment.list.filter((e) =>
        params._id ? e.animalId === params._id : e.animalId
      ),
    shallowEqual
  );
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState();

  useEffect(() => {
    const fetchData = async () => {
      // Si los datos ya se cargaron, no hacer más peticiones
      setTitle("Control Tratamiento Curativo");
      setChipList(treatmentRouteOptions(location));

      setIsLoading(true);
      await dispatch(TreatmentActions.list());
      setIsLoading(false);
    };

    fetchData();
  }, [dispatch, location, setChipList, setTitle]);

  const options = {
    selectableRows: "none",
    searchText,
    search: false,
    sortOrder: {
      name: "animalIdentifier",
      direction: "asc",
    },
  };

  const actionColumn = {
    label: "Acciones",
    name: "actions",
    options: {
      searchable: false,
      filter: false,
      sort: false,
      customBodyRenderLite: (dataIndex, rowIndex) => {
        return (
          <TableButtons
            onClickDeleteButton={(e) => {
              history.push(
                generatePath(ROUTES_DICT.health.treatment.delete, {
                  ...params,
                  _id: listTreatment[dataIndex]._id,
                })
              );
            }}
            onClickEditButton={() => {
              history.push(
                generatePath(ROUTES_DICT.health.treatment.update, {
                  ...params,
                  _id: listTreatment[dataIndex]._id,
                })
              );
            }}
          />
        );
      },
    },
  };

  return (
    <Grid item container xs={12}>
      <AddTreatment searchText={searchText} setSearchText={setSearchText} />
      <Grid item xs={12} className={classes.registerContainer}>
        <CustomMuiTable
          data={listTreatment}
          columns={[...columns, actionColumn]}
          options={options}
          isLoading={isLoading}
        />
      </Grid>
      {children()}
    </Grid>
  );
};

export default TreatmentPageList;
