import { format } from "date-fns";
import { ROUTES_DICT } from "../../../routes/routesDict";

export const iatfRouteOptions = (location) => [
  {
    key: "Inicio",
    path: ROUTES_DICT.service.listIATF,
    icon: "home",
  },
];

export const columns = [
  {
    label: "ID. del animal",
    name: "identifier",
    options: {
      filter: false,
    },
  },
  {
    label: "Nombre",
    name: "name",
    options: {
      filter: false,
    },
  },
  {
    label: "Edad en meses",
    name: "ageInMonths",
    options: {
      filter: false,
      customBodyRender: (value) => `${value} meses`,
    },
  },
  {
    label: "Raza",
    name: "racialType",
    options: {
      customBodyRender: (value) => value?.esLabel || "Desconocida",
    },
  },
];
