// import { alpha, createTheme } from "@material-ui/core";
// import { useTheme } from "@material-ui/styles";
import {
  CircularProgress,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import MUIDataTable from "mui-datatables";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

/**
 * @param {MaterialTableProps} props
 *
 */
function CustomMuiTable({ options, isLoading, data, columns, ...props }) {
  // Función para convertir los datos en una matriz y exportarlos a XLSX
  const handleDownloadXlsx = () => {
    // Se construye el encabezado usando la propiedad label de cada columna
    const header = columns.map((col) => col.label);
    // Convertir cada objeto de data en un arreglo siguiendo el orden de las columnas.
    // Si 'data' ya es un arreglo de arreglos, puedes usarlo directamente.
    const sheetData = [
      header,
      ...data.map((row) =>
        columns.map((col) => {
          // Si la columna 'acciones' (u otra personalizada) no tiene valor exportable, se puede devolver vacío
          return row[col.name] !== undefined ? row[col.name] : "";
        })
      ),
    ];

    // Crear la hoja de cálculo (worksheet) a partir de la matriz de datos
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    // Crear un nuevo libro (workbook) y agregar la hoja
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generar un buffer en formato XLSX
    const xlsxBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Crear un Blob y usar file-saver para iniciar la descarga
    const blob = new Blob([xlsxBuffer], { type: "application/octet-stream" });
    saveAs(blob, "data.xlsx");
  };

  const baseOptions = {
    download: false,
    textLabels: {
      pagination: {
        rowsPerPage: "Registros por página:",
        displayRows: "de",
        next: "Anterior página",
        previous: "Siguiente página",
      },
      toolbar: {
        search: "Buscar",
        // downloadCsv: "Descargar XLSX",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtrar tabla",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "LIMPIAR",
      },
      body: {
        noMatch: isLoading ? (
          <>
            <CircularProgress />
            <Typography>Cargando...</Typography>
          </>
        ) : (
          "No se encontraron registros"
        ),
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
    },
    customToolbar: () => (
      <Tooltip title="Descargar XLSX">
        <IconButton onClick={handleDownloadXlsx}>
          <GetAppIcon />
        </IconButton>
      </Tooltip>
    ),
  };

  return (
    <div>
      <MUIDataTable
        options={{ ...baseOptions, ...options }}
        data={data}
        columns={columns}
        {...props}
      />
    </div>
  );
}

export default CustomMuiTable;
