import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Button, Checkbox, CircularProgress } from "@material-ui/core";
import { Refresh, GetApp, Email } from "@material-ui/icons";
import { useStyles } from "./styles";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import CustomMuiTable from "../../../components/CustomMuiTable";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";

import AddIatf from "./components/AddIatf";

import { iatfRouteOptions, columns } from "./constans";

import AnimalActions from "../../../redux/actions/animal.actions";
import ReportActions from "../../../redux/actions/report.actions";

const IatfPageList = ({ children, setTitle, setChipList }) => {
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const animalList = useSelector((state) => state.animal.list, shallowEqual);

  const [loading, setLoading] = useState({
    table: false,
    reload: false,
    email: false,
  });

  const [filteredAnimals, setFilteredAnimals] = useState([]);
  const [selectedAnimals, setSelectedAnimals] = useState({});
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setTitle("Control IATF");
      setChipList(iatfRouteOptions(location));

      setLoading((prev) => ({ ...prev, table: true }));
      await dispatch(AnimalActions.list());
      setLoading((prev) => ({ ...prev, table: false }));
    };

    fetchData();
  }, [dispatch, location, setChipList, setTitle]);

  useEffect(() => {
    if (animalList.length > 0) {
      const filtered = animalList.filter((animal) => {
        const isFemale = animal.gender === "FEMALE";
        const ageInMonths = animal.birthDate
          ? Math.floor(
              (new Date() - new Date(animal.birthDate)) /
                (1000 * 60 * 60 * 24 * 30)
            )
          : 0;
        const meetsAgeCondition = ageInMonths >= 15;
        const meetsReproductiveState = animal.status?.esLabel
          ?.toLowerCase()
          .includes("vacía");
        const daysOpen = extractDaysOpen(animal.status?.esLabel);
        const meetsDaysOpenCondition = daysOpen >= 60;

        return (
          isFemale &&
          (meetsAgeCondition ||
            meetsReproductiveState ||
            meetsDaysOpenCondition)
        );
      });

      setFilteredAnimals(filtered);
      setSelectedAnimals(
        filtered.reduce((acc, animal) => ({ ...acc, [animal._id]: true }), {})
      );
    }
  }, [animalList]);

  const extractDaysOpen = (label) => {
    if (!label) return 0;
    const match = label.match(/(\d+)\s*día\(s\)\s*abiertos/);
    return match ? parseInt(match[1]) || 0 : 0;
  };

  const toggleSelectAll = () => {
    const newSelectAll = !Object.values(selectedAnimals).every((val) => val);
    setSelectedAnimals((prev) =>
      Object.keys(prev).reduce(
        (acc, id) => ({ ...acc, [id]: newSelectAll }),
        {}
      )
    );
  };

  const toggleSelection = (id) => {
    setSelectedAnimals((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const reloadData = () => {
    setLoading((prev) => ({ ...prev, reload: true }));
    dispatch(AnimalActions.list()).then(() => {
      setLoading((prev) => ({ ...prev, reload: false }));
    });
  };

  const exportToExcel = () => {
    const selectedData = filteredAnimals
      .filter((animal) => selectedAnimals[animal._id])
      .map((animal) => ({
        // ID: animal._id,
        Identificador: animal.identifier,
        Nombre: animal.name,
        EdadMeses: animal.birthDate
          ? Math.floor(
              (new Date() - new Date(animal.birthDate)) /
                (1000 * 60 * 60 * 24 * 30)
            )
          : "Desconocido",
        Raza: animal.racialType?.esLabel || "Desconocida",
      }));

    if (selectedData.length === 0) {
      alert("No hay datos seleccionados para exportar.");
      return;
    }

    const ws = XLSX.utils.json_to_sheet(selectedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "IATF_Animales");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    FileSaver.saveAs(data, "IATF_Animales.xlsx");
  };

  const sendReportByEmail = async () => {
    const selectedData = filteredAnimals
      .filter((animal) => selectedAnimals[animal._id])
      .map((animal) => ({
        Identificador: animal.identifier,
        Nombre: animal.name,
        EdadMeses: animal.birthDate
          ? Math.floor(
              (new Date() - new Date(animal.birthDate)) /
                (1000 * 60 * 60 * 24 * 30)
            )
          : "Desconocido",
        Raza: animal.racialType?.esLabel || "Desconocida",
      }));

    if (selectedData.length === 0) {
      alert("No hay datos seleccionados para enviar.");
      return;
    }

    setLoading((prev) => ({ ...prev, email: true }));
    await dispatch(ReportActions.sendReport(selectedData));
    setLoading((prev) => ({ ...prev, email: false }));
  };

  const options = {
    selectableRows: "none",
    searchText,
    search: true,
    sortOrder: {
      name: "identifier",
      direction: "asc",
    },
  };

  const actionColumn = {
    label: (
      <Checkbox
        checked={Object.values(selectedAnimals).every((val) => val)}
        onChange={toggleSelectAll}
      />
    ),
    name: "selection",
    options: {
      filter: false,
      sort: false,
      searchable: false,
      customBodyRenderLite: (dataIndex) => (
        <Checkbox
          checked={selectedAnimals[filteredAnimals[dataIndex]._id] || false}
          onChange={() => toggleSelection(filteredAnimals[dataIndex]._id)}
        />
      ),
    },
  };

  return (
    <Grid item container xs={12}>
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item>
          <Button
            className={classes.button}
            startIcon={
              loading.email ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <Email />
              )
            }
            onClick={sendReportByEmail}
            disabled={loading.email}
            style={{ color: "white" }}
          >
            {loading.email ? "Enviando..." : "Enviar al Correo"}
          </Button>
          <Button
            className={classes.button}
            startIcon={
              loading.reload ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <Refresh />
              )
            }
            onClick={reloadData}
            disabled={loading.reload}
            style={{ color: "white" }}
          >
            {loading.reload ? "Cargando..." : "Recargar"}
          </Button>
        </Grid>

        <Button
          className={classes.button}
          startIcon={<GetApp />}
          onClick={exportToExcel}
        >
          Exportar Excel
        </Button>
      </Grid>

      <AddIatf searchText={searchText} setSearchText={setSearchText} />

      <Grid item xs={12} style={{ margin: "10px", textAlign: "left" }}>
        <strong>Total:</strong> {filteredAnimals.length} -{" "}
        <strong>Seleccionados:</strong>{" "}
        {Object.values(selectedAnimals).filter(Boolean).length}
      </Grid>

      <Grid item xs={12} className={classes.registerContainer}>
        <CustomMuiTable
          data={filteredAnimals}
          columns={[actionColumn, ...columns]}
          options={options}
          isLoading={loading.table}
        />
      </Grid>
      {children()}
    </Grid>
  );
};

export default IatfPageList;
