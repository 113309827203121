import * as yup from "yup";
import { Formik, FieldArray } from "formik";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { Grid, Typography, IconButton, Button } from "@material-ui/core";
import { Add, RemoveCircle } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { useStyles } from "../styles";
import { eventTypeOptions } from "../../../constants";

import ButtonFormik from "../../../components/Inputs/ButtonFormik";
import CustomModal from "../../../components/Modal";
import TextFieldFormik from "../../../components/Inputs/TextFieldFormik";
import SelectFieldFormik from "../../../components/Inputs/SelectFieldFormik";
import SearchFieldFormik from "../../../components/Inputs/SearchFieldFormik";
import DatePickerFieldFormik from "../../../components/Inputs/DatePickerFieldFormik";

import EventActions from "../../../redux/actions/event.actions";
import AnimalActions from "../../../redux/actions/animal.actions";

const defaultInitValues = {
  agribusinessId: "",
  animalId: "",
  title: "",
  description: "",
  type: "OTHER",
  eventDates: [new Date()],
  completed: false,
};

function EventForm({
  initValues = defaultInitValues,
  type = "create",
  hideAnimal = false,
  onClickCancelButton,
  onCompleteSubmit = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();

  const listAnimals = useSelector((state) => state.animal.list);
  const currentAgribusiness = useSelector(
    (state) => state.agribusiness.current
  );

  useEffect(() => {
    if (!listAnimals || listAnimals.length === 0) {
      dispatch(AnimalActions.list());
    }

    if (hideAnimal) {
      initValues.animalId = params._id;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, listAnimals]);

  const validationSchema = yup.object().shape({
    animalId: yup.string().required("Seleccione un animal."),
    title: yup.string().required("Ingrese el título del evento."),
    type: yup.string().required("Seleccione un tipo de evento."),
    eventDates: yup
      .array()
      .of(yup.date().required("Ingrese una fecha válida."))
      .min(1, "Debe ingresar al menos una fecha."),
    description: yup.string().max(1000, "Máximo 1000 caracteres."),
  });

  const preSubmit = (values) => {
    onSubmit(values);
  };

  const onSubmit = (values) => {
    values.agribusinessId = currentAgribusiness._id;

    if (type === "create") {
      dispatch(EventActions.create(values))
        .then(() => {
          onClickCancelButton();
        })
        .catch((error) => {
          console.error("Error creando el evento:", error);
        });
    }
    if (type === "update") {
      dispatch(EventActions.update(values))
        .then(() => {
          onClickCancelButton();
        })
        .catch((error) => {
          console.error("Error actualizando el evento:", error);
        });
    }
  };

  return (
    <>
      <Formik
        initialValues={initValues}
        onSubmit={preSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} className={classes.formStyle}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {type === "create" ? "Nuevo Evento" : "Editar Evento"}
              </Typography>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant={"subtitle2"}>Datos del Evento</Typography>
              </Grid>
              {!hideAnimal && (
                <SearchFieldFormik
                  onChange={(event, value) =>
                    props.setFieldValue("animalId", value ? value._id : "")
                  }
                  options={listAnimals.map((animal) => ({
                    _id: animal._id,
                    identifier: `${animal.identifier} - ${animal.name}`,
                  }))}
                  label="Animal"
                  name="animalId"
                  lg={6}
                  sm={6}
                  xs={12}
                  // disabled={type !== "create"}
                  defaultValue={type === "create" ? null : props.values.animal}
                />
              )}
              <TextFieldFormik
                label="Título"
                name="title"
                onChange={props.handleChange}
                lg={6}
                sm={6}
                xs={12}
              />
              <SelectFieldFormik
                onChange={props.handleChange}
                options={eventTypeOptions}
                label="Tipo de evento"
                name="type"
                lg={6}
                sm={6}
                xs={12}
              />
              <Grid item xs={6}>
                <SelectFieldFormik
                  label="Estado"
                  name="completed"
                  options={[
                    { _id: true, name: "Completado" },
                    { _id: false, name: "Pendiente" },
                  ]}
                  onChange={props.handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant={"subtitle2"}>Fechas del Evento</Typography>
              </Grid>

              <FieldArray
                name="eventDates"
                render={(arrayHelpers) => (
                  <>
                    {props.values.eventDates.map((date, index) => (
                      <Grid
                        container
                        spacing={1}
                        key={index}
                        alignItems="center"
                      >
                        <Grid item xs={10}>
                          <DatePickerFieldFormik
                            label={`Fecha ${index + 1}`}
                            name={`eventDates.${index}`}
                            onChange={props.handleChange}
                            lg={12}
                            sm={12}
                            xs={12}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            onClick={() => arrayHelpers.remove(index)}
                            disabled={props.values.eventDates.length === 1}
                          >
                            <RemoveCircle color="error" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}

                    {/* Asegurar que el botón se vea */}
                    <Grid
                      container
                      justifyContent="center"
                      style={{ margin: "20px" }}
                    >
                      <Button
                        size="small"
                        className={classes.cardIcon}
                        label=" + Agregar fecha"
                        onClick={() => arrayHelpers.push(new Date())}
                      >
                        <Add fontSize="small" />
                        <Typography variant="body1">Agregar fecha</Typography>
                      </Button>
                    </Grid>
                  </>
                )}
              />

              <TextFieldFormik
                label="Descripción"
                name="description"
                onChange={props.handleChange}
                multiline
                rows={3}
                xs={12}
              />
            </Grid>

            <Grid container spacing={1}>
              <Grid item container xs={12} justifyContent={"flex-end"}>
                <Grid item xs={3} className={classes.paddingButton}>
                  <ButtonFormik
                    xs={3}
                    label="Cancelar"
                    type="cancel"
                    onClick={onClickCancelButton}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ButtonFormik xs={3} label="Guardar" type="submit" />
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <CustomModal />
    </>
  );
}

export default EventForm;
