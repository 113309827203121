import ApiVL from "../helpers/virtualLabsApi";

const prefixRoute = "race";

class RaceService {
  // Código anterior (usando window.icAPI):
  // const raceList = () => {
  //   return new Promise((resolve, reject) => {
  //     window.icAPI.callService("raceList", {}, function (error, response) {
  //       if (!error) {
  //         resolve(response.responseJSON);
  //       } else {
  //         reject(response);
  //       }
  //     });
  //   });
  // };

  // Nueva versión (usando ApiVL con prefixRoute):
  async raceList() {
    try {
      const response = await ApiVL.fetch({
        serviceName: `${prefixRoute}/List`,
        body: {},
      });
      return response;
    } catch (error) {
      console.log(error);
      const rejectBody = {
        message: error?.message || "Error desconocido",
      };
      throw rejectBody;
    }
  }
}

export default new RaceService();

// const RaceService = {
//   raceList,
// };

// export default RaceService;
